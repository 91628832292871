import { CustomTooltip, LinkButton } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectLoginButtonTooltipCookieState } from '../../selectors/cookies/selectLoginButtonTooltipCookieState';
import { setTooltipCloseCookies } from '../../utils/cookie';

interface ILoginButtonProps {
  onAuthenticateClick(): any;
}

export const LoginButton: React.FC<ILoginButtonProps> = ({ onAuthenticateClick }) => {
  const [returnUrl, setReturnUrl] = React.useState<string | null>(null);
  const [isOpen, setOpen] = React.useState(false);

  const tooltipRef = React.useRef<HTMLAnchorElement>(null);

  const loginButtonTooltipCookie = useSelector(selectLoginButtonTooltipCookieState);

  const handleLocationChange = React.useCallback(() => {
    setReturnUrl(window.location.href);
  }, []);

  React.useEffect(() => {
    setReturnUrl(window.location.href);

    window.addEventListener('popstate', handleLocationChange);

    return () => window.removeEventListener('popstate', handleLocationChange);
  }, [handleLocationChange]);

  React.useEffect(() => {
    setOpen(!loginButtonTooltipCookie);
  }, [loginButtonTooltipCookie]);

  const handleClose = React.useCallback(() => {
    if (!isOpen) {
      setOpen(false);
      setTooltipCloseCookies();
    }
  }, [tooltipRef]);

  const handleAuthenticateClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      onAuthenticateClick();
    },
    [onAuthenticateClick],
  );

  return (
    <>
      <LinkButton
        href={`/authenticate/${returnUrl ? `?returnUrl=${returnUrl}` : ''}`}
        size="XS"
        theme="fill_secondary"
        onClick={handleAuthenticateClick}
        ref={tooltipRef}
      >
        Войти
      </LinkButton>
      <CustomTooltip
        outside
        open={isOpen}
        onClose={handleClose}
        placement="bottom-start"
        theme="black"
        content="Войдите, чтобы получать уведомления про&nbsp;новые подходящие объявления"
        anchorRef={tooltipRef}
        arrow={true}
        closeButton
        maxWidth={320}
      />
    </>
  );
};
