import { ErrorLogComponent } from '@cian/error-log-component';
import { Provider as MicrofrontendProvider } from '@cian/mf-react';
import { NoSsr } from '@cian/react-utils';
import { UiKitRoot } from '@cian/ui-kit/root';
import * as React from 'react';
import { Provider } from 'react-redux';

import { IApplicationContext } from '../../types/applicationContext';
import { TReduxStore } from '../../types/redux';
import { ApplicationContext } from '../../utils/applicationContext';
import { CatProfilingModalContainer } from '../CatProfilingModalContainer';
import { ChatsWidgetContainer } from '../ChatsWidgetContainer';
import { CookiesNotificationContainer } from '../CookiesNotificationContainer';
import { FeedbackFormContainer } from '../FeedbackFormContainer';
import { HeaderLoadable as Header } from '../HeaderContainer';
import { MergeNotificationContainer } from '../MergeNotificationContainer';
import { MobileAppFullscreenBannerContainer } from '../MobileAppFullscreenBannerContainer';
import { OnboardingContainer } from '../OnboardingContainer';
import { PinnedButtonContainer } from '../PinnedButtonContainer';

export interface IApplicationProps {
  context: IApplicationContext;
  reduxStore: TReduxStore;
}

export const Application: React.FC<IApplicationProps> = ({ reduxStore, context }) => {
  const {
    logger,
    custom: { initialDeviceType },
    microfrontendsRegistry,
  } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <UiKitRoot deviceType={initialDeviceType || 'phone'}>
          <Provider store={reduxStore}>
            <MicrofrontendProvider registry={microfrontendsRegistry}>
              <MergeNotificationContainer />
              <CookiesNotificationContainer />
              <Header />
              <FeedbackFormContainer />
              <ChatsWidgetContainer />
              <NoSsr>
                <MobileAppFullscreenBannerContainer />
                <CatProfilingModalContainer />
                <PinnedButtonContainer />
                <OnboardingContainer />
              </NoSsr>
            </MicrofrontendProvider>
          </Provider>
        </UiKitRoot>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
