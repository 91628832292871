import { useDeviceType } from '@cian/ui-kit';
import { useMemo } from 'react';

export function useNewMobileAppBannerEntryPoint(): boolean {
  const deviceType = useDeviceType();

  return useMemo(
    () => deviceType === 'phone',
    // Вычисляем однократно, чтобы баннер не появлялся при ресайзе
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
