import { EMyHomeFrom } from '../../containers/MyHomeDesktopContainer/utils/constants';
import { IHeaderData, TGetHeaderResponse } from '../../headerData';
import { IApplicationContext } from '../../types/applicationContext';
import { ELkType } from '../../types/lkType';
import { prepareDeveloperBanner } from '../prepareDeveloperBanner';

export function prepareHeaderData(
  { custom: { mainPageUrl } }: IApplicationContext,
  response: TGetHeaderResponse,
): IHeaderData {
  const userCabinetUrl = response.data.lkUrl ?? 'https://my.cian.ru/';

  /* istanbul ignore next */
  return {
    user: {
      userId: response.data.userId ?? null,
      realtyId: response.data.realtyUserId ?? null,
      firstName: response.data.firstName ?? null,
      lastName: response.data.lastName ?? null,
      companyName: response.data.companyName ?? null,
      isAuthenticated: !!response.data.isAuthenticated,
      isBuilder: !!response.data.isBuilder,
      balance: response.data.balance ?? 0,
      avatarUrl: response.data.avatarUrl ?? null,
      userType: response.data.lkType ?? ELkType.Searcher,
      phone: response.data.phone ?? null,
      email: response.data.email ?? null,
      hasBlockedSanction: !!response.data.hasBlockedSanction,
    },
    userArea: {
      userCabinetUrl,
      payUrl: response.data.lkBalanceUrl ?? 'https://my.cian.ru/pay/',
      profileUrl: response.data.lkLinks?.profile ?? 'https://my.cian.ru/profile/',
    },
    favorites: {
      count: response.data.favoritesCount ?? 0,
    },
    messages: {
      count: 0,
    },
    savedSearches: {
      count: response.data.storedFiltersCount ?? 0,
    },
    leads: {
      count: response.data.leadsCount ?? 0,
    },
    deals: {
      count: response.data.activeDealsCount ?? 0,
    },
    myHome: {
      isExist: false,
    },
    rubricsLinks: {
      rent: response.data.listingLinks?.rent ?? null,
      rentRoom: response.data.listingLinks?.roomRent ?? null,
      rentCottage: response.data.listingLinks?.cottageRent ?? null,
      sale: response.data.listingLinks?.sale ?? null,
      saleApartment: response.data.listingLinks?.apartmentSale ?? null,
      saleResidentialComplexesApartment: response.data.listingLinks?.newobjectsApartmentSale ?? null,
      saleSecondaryApartment: response.data.listingLinks?.secondaryApartmentSale ?? null,
      saleRoom: response.data.listingLinks?.roomSale ?? null,
      saleCottage: response.data.listingLinks?.cottageSale ?? null,
      saleLandplot: response.data.listingLinks?.landplotSale ?? null,
      residentialComplexesCatalog: response.data.jksNewobjectsUrl ?? null,
      howToSaleSecondaryApartment: response.data.otherLinks?.secondaryApartmentSaleHowto ?? null,
      residentialComplexes: response.data.listingLinks?.newobjects ?? null,
      villages: response.data.listingLinks?.villages ?? null,
      rentDaily: response.data.listingLinks?.dailyRent ?? null,
      commercial: response.data.listingLinks?.commercial ?? null,
      rentOffice: response.data.listingLinks?.officeRent ?? null,
      rentCoworking: response.data.listingLinks?.coworkingRent ?? null,
      rentShoppingArea: response.data.listingLinks?.shoppingAreaRent ?? null,
      rentWarehouse: response.data.listingLinks?.warehouseRent ?? null,
      saleOffice: response.data.listingLinks?.officeSale ?? null,
      saleShoppingArea: response.data.listingLinks?.shoppingAreaSale ?? null,
      saleWarehouse: response.data.listingLinks?.warehouseSale ?? null,
      saleReadyBusiness: response.data.listingLinks?.readyBusinessSale ?? null,
      businessCenters: response.data.listingLinks?.businessCenters ?? null,
      shoppingCenters: response.data.listingLinks?.shoppingCenters ?? null,
      warehouses: response.data.listingLinks?.warehouses ?? null,
      mortgage: response.data.mortgageLinks?.main ?? null,
      mortgagePersonalOffers: response.data.mortgageLinks?.personalOffers ?? null,
      mortgageZeroOne: response.data.mortgageLinks?.zeroOne ?? null,
      mortgageGovHelp: response.data.mortgageLinks?.govHelp ?? null,
      mortgageCenter: response.data.mortgageLinks?.center ?? null,
      mortgageCalculator: response.data.mortgageLinks?.calculator ?? null,
      mortgageConsumerCredit: response.data.mortgageLinks?.consumerCredit ?? null,
      suburbanMortgage: response.data.mortgageLinks?.suburbanCredit ?? null,
      mortgageBrokerCalculator: response.data.mortgageLinks?.brokerCalculator ?? null,
      mortgageBrokerPromo: response.data.mortgageLinks?.brokerPromo ?? null,
      magazine: response.data.magazineLinks?.main ?? null,
      magazineSuburban: response.data.magazineLinks?.suburban ?? null,
      magazineDocuments: response.data.magazineLinks?.documents ?? null,
      magazineRent: response.data.magazineLinks?.rent ?? null,
      magazineApartmentEvaluation: response.data.magazineLinks?.apartmentEvaluation ?? null,
      magazineAnalytics: response.data.magazineLinks?.analytics ?? null,
      magazineDisountMortgage: response.data.magazineLinks?.discountMortgage ?? null,
      magazineMortgage: response.data.magazineLinks?.mortgage ?? null,
      magazineNewbuildingVsSecondary: response.data.magazineLinks?.newbuildingVsSecondary ?? null,
      magazineOnlineDeal: response.data.magazineLinks?.onlineDeal ?? null,
      newbuildingPromosUrl: response.data?.newbuildingPromosUrl ?? null,
      newbuildingServicesKpn: response.data?.kpnServicesUrl ?? null,
      askAgent: response.data.otherLinks?.realtorsForum ?? null,
      evaluation: response.data.otherLinks?.calculator ?? null,
      investCalculator: response.data.otherLinks?.calculatorInvest ?? null,
      residentialComplexesCalculator: response.data.otherLinks?.calculatorJk ?? null,
      businessAnalytics: response.data.otherLinks?.b2bAnalytics ?? null,
      marketAnalytics: response.data.otherLinks?.marketAnalytics ?? null,
      realtorSelection: response.data.otherLinks?.realtorSelection ?? null,
      promotions: response.data.otherLinks?.promotions ?? null,
      dealMain: response.data.otherLinks?.dealMain ?? null,
      legalCheck: response.data.otherLinks?.legalCheck ?? null,
      countryside: response.data.listingLinks?.countryside ?? null,
      cottageSale: response.data.listingLinks?.cottageSale ?? null,
      landplotSale: response.data.listingLinks?.landplotSale ?? null,
      countrysideRent: response.data.listingLinks?.countrysideRent ?? null,
      townhouseSale: response.data.listingLinks?.townhouseSale ?? null,
      suburban: response.data.magazineLinks?.suburban ?? null,
      countrysideKp: response.data.listingLinks?.countrysideKp ?? null,
      houseSale: response.data.listingLinks?.houseSale ?? null,
      landSale: response.data.listingLinks?.landSale ?? null,
      rentAddform: response.data.addformLinks?.rent ?? null,
      saleAddform: response.data.addformLinks?.sale ?? null,
      suburbanAddform: response.data.addformLinks?.suburban ?? null,
      suburbanBuilders: response.data.countrysideBuilders ?? null,
      suburbanBuildersProjects: response.data.suburbanBuildersProjects ?? null,
      suburbanMortgageLanding: response.data.mortgageLinks?.suburbanMortgage ?? null,
      buildingApplication: response.data.suburbanBuildingRequest ?? null,
      layoutsCatalog: response.data.newbuildingLayoutsCatalog ?? null,
    },
    servicesLinks: {
      deals: null,
      complaints: null,
      hiddenOffers: null,
      search: mainPageUrl,
      placeOffer: response.data.otherLinks?.addForm ?? null,
      favorites: response.data.lkLinks?.favorites ?? null,
      calls: response.data.lkLinks?.calls ?? null,
      statistics: response.data.lkLinks?.statistic ?? null,
      leads: response.data.lkLinks?.leads ?? null,
      savedSearches: response.data.lkLinks?.savedSearch ?? null,
      helpCenter: response.data.otherLinks?.helpCenter ?? null,
      offers: response.data.lkLinks?.offers ?? null,
      settings: response.data.lkLinks?.settings ?? null,
      professionalSearch: response.data.lkType === ELkType.Specialist ? `${userCabinetUrl}offers` : null,
      messages: `${mainPageUrl}/dialogs/`,
      reportProblem: `${mainPageUrl}/contacts/`,
      notifications: response.data.lkLinks?.notifications ?? null,
      mortgageCalculator: response.data.mortgageLinks?.calculator ?? null,
      mortgageForm: response.data.mortgageLinks?.anketa ?? null,
      agentHelpRequest: response.data.otherLinks?.leadRealtorForm ?? null,
      allServicesPromo: response.data.otherLinks?.allServicesPromo ?? null,
      flatValuation: `${mainPageUrl}/kalkulator-nedvizhimosti/`,
      saleRent: `${mainPageUrl}/application-form/?fromSaleRent=1`,
      subscriptions: `${mainPageUrl}/subscriptions`,
      businessAnalytics: response.data.otherLinks?.b2bAnalytics ?? null,
      marketPulse: response.data.otherLinks?.marketAnalytics ?? null,
      mortgageBroker: response.data.otherLinks?.mortgageBroker ?? null,
      myHome: `${mainPageUrl}/my-home/${EMyHomeFrom.fromHeader}`,
      cianApp: `${mainPageUrl}/mobile-app/`,
    },
    dropdownLinks: {
      map: response.data.otherLinks?.map ?? null,
      helpCenter: response.data.otherLinks?.helpCenter ?? null,
      developers: response.data.catalogDevelopers,
      realtors: response.data.catalogRealtors,
      realtorsForum: response.data.otherLinks?.realtorsForum || null,
      layoutsCatalog: response.data.newbuildingLayoutsCatalog || null,
      cianCareer: response.data.otherLinks?.cianCareer || null,
    },
    developerBanner: prepareDeveloperBanner(response.data.developerBanners || []),
  };
}
