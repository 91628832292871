import { IServicesLinksState } from '../../types/servicesLinks';

const defaultState: IServicesLinksState = {
  deals: null,
  complaints: null,
  hiddenOffers: null,
  agentHelpRequest: null,
  businessAnalytics: null,
  favorites: null,
  calls: null,
  statistics: null,
  leads: null,
  savedSearches: null,
  offers: null,
  professionalSearch: null,
  flatValuation: '',
  helpCenter: null,
  marketPulse: null,
  mortgageCalculator: null,
  mortgageForm: null,
  placeOffer: null,
  messages: '',
  myHome: '',
  reportProblem: '',
  notifications: null,
  search: '',
  saleRent: '',
  subscriptions: '',
  allServicesPromo: null,
  settings: null,
  mortgageBroker: null,
  cianApp: '',
};

export function servicesLinksReducer(state: IServicesLinksState = defaultState): IServicesLinksState {
  return state;
}
